import React from "react";
import { Typography, Button, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

const BuyMeACoffeeView = ({ type, children }, props) => {
  const data = useStaticQuery(graphql`
    query {
      coffee: file(relativePath: { eq: "img/coffee/coffee.png" }) {
        childImageSharp {
          image: gatsbyImageData(width: 40)
        }
      }
    }
  `);

  return (
    <Box>
      <Button
        href="https://www.buymeacoffee.com/tanaschita"
        target="_blank"
        rel="noopener noreferrer"
      >
        <BuyMeACoffeeImage
          image={data.coffee.childImageSharp.image}
          alt="Image of a coffee"
        />
        <Typography variant="button">Buy me a coffee</Typography>
      </Button>
    </Box>
  );
};

export { BuyMeACoffeeView };

const BuyMeACoffeeImage = styled(GatsbyImage)(({ theme }) => ({
  marginRight: "20px",
  marginLeft: "10px",
}));
