import React from "react";
import { Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

const NewsLetterButton = ({ type, children }, props) => {
  const data = useStaticQuery(graphql`
    query {
      marmot: file(relativePath: { eq: "img/newsletter/marmot.png" }) {
        childImageSharp {
          image: gatsbyImageData(width: 60)
        }
      }
    }
  `);

  return (
    <div>
      <Button href="https://www.tanaschita.com/newsletter/">
        <MarmotImage
          image={data.marmot.childImageSharp.image}
          alt="Image of a reading marmot"
        />
        <Typography variant="button">Subscribe</Typography>
      </Button>
    </div>
  );
};

export { NewsLetterButton };

const MarmotImage = styled(GatsbyImage)(({ theme }) => ({
  textDecoration: "none",
  marginRight: "20px",
}));
