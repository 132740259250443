import React from "react";
import { Container, Box, Button, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { graphql } from "gatsby";
import { useState } from "react";
import AffiliateView from "../components/affiliateView";
import ArticleGridItem from "../components/articleGridItem";
import AuthorAndSocialMediaComponent from "../components/authorAndSocialMediaComponent";
import { BuyMeACoffeeView } from "../components/buyMeACoffee";
import { NewsLetterButton } from "../components/newsLetterButton";
import Seo from "../components/seo";
import SocialMediaComponent from "../components/socialMediaComponent";
import TagsComponent from "../components/tagsComponent";
import { SubHeadline } from "../styles/globalStyles";
import Layout from "../styles/layout";

const TitleButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "baseline",
  marginTop: "12px",
}));

export default function Home({ props, data, location }) {
  const [expandArticles, setExpandArticles] = useState(false);
  const countOfArticlesToShow = 12;
  const [articles, setArticles] = useState(
    data.articles.nodes.slice(0, countOfArticlesToShow)
  );

  /*const jobInterviewArticles = data.articles.nodes
    .filter((article) =>
      article.frontmatter.tags.includes("technical interview")
    )
    .reverse();*/

  const toggleExpandArticles = () => {
    let newExpand = !expandArticles;
    setExpandArticles(newExpand);
    if (newExpand) {
      setArticles(data.articles.nodes);
    } else {
      setArticles(data.articles.nodes.slice(0, countOfArticlesToShow));
    }
  };

  const tags = data.tags.group.map(function (tag) {
    return tag.fieldValue;
  });

  // Preview Newsletter: Stay up to date with guides and tutorials on Swift and iOS Development topics from tanaschita.com
  return (
    <Layout location={location}>
      <Container>
        <Seo
          title="Weekly articles about Swift and iOS Development"
          description="Learn Swift and iOS Development on tanaschita.com. Stay up to date with guides and tutorials on topics like SwiftUI, Combine, concurrency with async/await and more."
        />
        <Typography variant="h1">
          Weekly articles about Swift and iOS Development
        </Typography>
        <Box mt={2}></Box>
        <AuthorAndSocialMediaComponent></AuthorAndSocialMediaComponent>
        <TitleButtonContainer>
          <Typography variant="h2">Discover articles by topics</Typography>
        </TitleButtonContainer>
        <Box height="12px"></Box>
        <TagsComponent tags={tags} isLargerSize={true}></TagsComponent>
        <AffiliateView type="sponsor"></AffiliateView>
        <TitleButtonContainer>
          <Typography variant="h2">Latest articles and tips</Typography>
          <Box width="16px"></Box>
          <Button>
            <Typography variant="button" onClick={toggleExpandArticles}>
              {expandArticles ? "Show less" : "Show all"}
            </Typography>
          </Button>
        </TitleButtonContainer>
        <Grid container spacing={1}>
          {articles.map((node) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={node.id}>
                <ArticleGridItem article={node}></ArticleGridItem>
              </Grid>
            );
          })}
        </Grid>
        <Button
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "12px",
          }}
        >
          <Typography variant="button" onClick={toggleExpandArticles}>
            {expandArticles ? "Show less articles" : "Show all articles"}
          </Typography>
        </Button>

        <SubHeadline>Say hi</SubHeadline>
        <SocialMediaComponent />

        <SubHeadline>Newsletter</SubHeadline>
        <NewsLetterButton />

        <SubHeadline>Like to support my work?</SubHeadline>
        <BuyMeACoffeeView />
      </Container>
    </Layout>
  );
}

export const query = graphql`
  {
    articles: allMdx(
      filter: { fields: { contentType: { eq: "article" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          subtitle
          date(formatString: "DD MMM YYYY")
          tags
          readingTime
        }
        slug
        id
      }
    }

    tips: allMdx(
      filter: { fields: { contentType: { eq: "tip" } } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 200
    ) {
      nodes {
        frontmatter {
          title
          subtitle
          date(formatString: "DD MMM YYYY")
          tags
        }
        slug
        id
      }
    }

    tags: allMdx {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
